import React from "react";
import "./ExpertiseCard.css";

function ExpertiseCard({
  title,
  categories,
  description,
  image,
  imagePosition,
  is_mobile,
}) {
  const isLeftImage = imagePosition === "left";

  return (
    <>
      <div className="flex flex-col p-6 justify-center background-Expertise-Card">
        <div data-theme="teal" className="mx-auto max-w-8xl">
          <section className="font-sans text-black">
            <div
              className={`lg:flex lg:items-center ${
                isLeftImage ? "lg:flex-row-reverse" : ""
              } [ fancy-corners fancy-corners--large fancy-corners--top-left fancy-corners--bottom-right ]`}
            >
              <div
                className={`flex-shrink-0 self-stretch sm:flex-basis-40 md:flex-basis-50 xl:flex-basis-60 ${
                  isLeftImage ? "ml-0 sm:mr-9" : "mr-0 sm:ml-9"
                }`}
              >
                <div className="h-full">
                  <article className="h-full">
                    <div className="h-full">
                      <img
                        className="h-full object-cover"
                        src={image}
                        width="600"
                        height="412"
                        alt=""
                      />
                    </div>
                  </article>
                </div>
              </div>
              <div
                className={` bg-grey ${
                  isLeftImage ? "mr-0 sm:ml-9" : "ml-0 sm:mr-9"
                }`}
              >
                <div className="leading-relaxed mt-6">
                  <h2 className="leading-tight text-lg sm:text-4xl md:text-3xl font-bold mb-3 sm:mb-4 md:mb-5 ">
                    {title}
                  </h2>
                  {categories && categories.length > 0 && (
                    <div className="categories space-x-2 md:space-x-2 sm:space-x-1">
                      {categories.map((category, index) => (
                        <span
                          key={index}
                          className="font-semibold text-emerald-400"
                        >
                          {`#${category}`}
                        </span>
                      ))}
                    </div>
                  )}{" "}
                  <p className="mt-4 text-gray-400">{description}</p>
                  <p>
                    {/* <a
                      className="mt-4 button button--secondary"
                      href="https://inviqa.com/cxcon-experience-transformation"
                    >
                      Explore this event
                    </a> */}
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default ExpertiseCard;
