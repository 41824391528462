import { combineReducers } from "redux";
import expertiseReducer from "./Expertiseitems";
import portfolioReducer from "./Portfolioitems";

const rootReducer = combineReducers({
  portfolio: portfolioReducer,
  expertise: expertiseReducer,
  // Add more reducers as needed
});

export default rootReducer;
