import React from "react";
import Footer from "../../layout/Footer/Footer";
import AboutMe from "./About/About";
import Hero from "./Hero/Hero";
import "./Home.css";
import Portfolio from "./Portfolio/Portfolio";
import StandaloneSection from "./StandaloneSection/StandaloneSection";
import Technologies from "./Technologies/Technologies";
import Testimonial from "./Testimonial/Testimonial";
function Home() {
  return (
    <>
      <Hero />
      <AboutMe />
      <StandaloneSection />
      <Portfolio />
      {/* <Technologies /> */}
      <Testimonial />
    </>
  );
}

export default Home;
