import React, { useState } from "react";
import "./CardGrid.css"; // Add your CSS styles here
const CardGrid = ({ text, Description }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleCardClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    // <div
    //   className={`AboutCard ${isExpanded ? "expanded" : ""}`}
    //   onClick={handleCardClick}
    // >
    //   <div className="cardImageContainer">
    //     {/* <img
    //       className="cardImage"
    //       src={image} // Replace this with the URL of your image
    //       alt="Description of the image"
    //     /> */}
    //   </div>
    //   <h1 className="title uppercase">{text}</h1>
    //   <div className="text1">
    //     <div className="text-content">
    //       <div className="body-text">{Description} </div>
    //     </div>
    //   </div>{" "}
    //   {/* <Chevron /> */}
    // </div>
    <div className="">
    <main class="l-card">
      <section class="l-card__text">
        <p>{text}</p>
        {/* <p>{Description} </p> */}
      </section>
      <section class="l-card__user">
        <div class="l-card__userImage">
          {/* <img src="https://avatars.githubusercontent.com/u/20525486?v=4" alt="Naruto" />  */}
        </div>
        <div class="l-card__userInfo">
          {/* <span>{text}</span> */}
          <span>{Description}</span>
        </div>
      </section>
    </main>
    </div>
  );
};

export default CardGrid;
