import { default as React } from "react";
import { connect } from "react-redux";

import ExpertiseCard from "../../components/Card/ExpertiseCard/ExpertiseCard";
import Breadcrumbs from "../../layout/Breadcrumbs/Breadcrumbs";
import "./Expertise.css";

function Expertise({ isMobile, expertiseCards }) {
  
  return (
    <>
      <div className="background-Expertise-container mt-24 bg-center bg-cover sm:bg-center sm:bg-cover md:bg-top">
        <div className="flex flex-wrap justify-center items-center mb-8 p-4 md:p-9">
          <div className="w-full md:w-5/8 lg:w-7/8 md:mb-36 lg:mb-36 sm:mb-12">
            <div className="float-left ml-6">
              <Breadcrumbs
                firsthand="Home"
                Secondhand="Expertise"
                Redirected="/Expertise"
              />
            </div>
            <div className="intro-left mt-4 md:mt-0">
              <div className="intro-name">
                <h3 className="uppercase font-bold md:mt-14 animate-fade-in">
                  Breaking Limits:
                </h3>
                <br />
                <h2 className="Subtitle mt-4 font-semibold space-4 animate-fade-in Portfolio-paragraph">
                  <span className="text-blue-800 mr-2">Expertise</span> in
                  Crafting Memorable Journeys and Unforgettable Customer
                  Experiences
                </h2>
              </div>
            </div>
          </div>

          {/* <div>
              <img src={image} alt="" width={300} />
            </div> */}
        </div>
      <div className="Expertise-Card">
        <div className="mx-auto max-w-3xl text-center mt-10 ">
          <h3 className="mb-6 text-3xl font-bold">
            <span className="custom-double-underline">Our Expertise</span>
          </h3>
          {/* </div> */}
        </div>


        {expertiseCards.map((card) => (
          <ExpertiseCard
            key={card.id}
            title={card.title}
            categories={card.categories}
            description={card.description}
            image={card.image}
            imagePosition={card.imagePosition}
            is_mobile={isMobile ? "mobile" : "web"}
            />
          ))}
          </div>
      </div>
    </>
  );
}



const mapStateToProps = (state) => ({
  isMobile: state.expertise.isMobile,
  expertiseCards: state.expertise.expertiseCards,
});

export default connect(mapStateToProps)(Expertise);