import { React, useState } from "react";
import './StandaloneSection.css';
import Tooltip from "./Tooltip/Tooltip";
function StandaloneSection() {
  const [activeTooltip, setActiveTooltip] = useState("F"); // Initialize with "F" as the default active tooltip

   const tooltipDescriptions = [
     "Emphasizing your commitment to future-proof solutions.",
     "Highlighting your expertise in optimizing processes and resources.",
     "Showcasing your ability to enhance connectivity and communication.",
     "Signifying your dedication to creating user-friendly experiences.",
     "Demonstrating your proficiency in designing scalable solutions.",
   ];

  return (
    <>
      <div className="Standalonbody">
        <div className="flex flex-col md:flex-row">
          <div className="md:w-1/2 lg:w-1/3 xl:w-2/5">
            <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold dark:text-white md:text-gray-800 dark:text-gray-800 pl-4 md:pl-20 py-8 md:py-16 order-2 md:order-1">
              We Prioritize Your F.O.C.U.S.
            </h1>
          </div>
          <div className="md:w-1/2 lg:w-2/3 xl:w-3/5">
            <p className="text-sm md:text-md lg:text-lg text-gray-500 md:text-gray-500 dark:text-gray-500 px-4 md:px-20 py-4 md:py-16 order-1 md:order-2">
              "At TechnoScaffold, our journey has been marked by an unwavering
              commitment to excellence. We proudly stand as the preferred choice
              for technology-driven clients, offering a diverse range of
              services meticulously tailored to the distinctive needs of our
              dynamic clientele. Our core strength lies in harmoniously uniting
              engineering prowess with profound business domain expertise,
              ensuring the consistent delivery of excellence in project
              execution. We thrive by focusing on five fundamental F.O.C.U.S.
              areas."
            </p>
          </div>
        </div>
        <div className="flex items-center justify-center Maintooltip">
          <Tooltip
            Title="F"
            Subtitle="Futures"
            descriptions={[tooltipDescriptions[0]]}
            activeTooltip={activeTooltip}
            setActiveTooltip={setActiveTooltip}
          />
          <Tooltip
            Title="O"
            Subtitle="Optimization"
            descriptions={[tooltipDescriptions[1]]}
            activeTooltip={activeTooltip}
            setActiveTooltip={setActiveTooltip}
          />
          <Tooltip
            Title="C"
            Subtitle="Connectivity"
            descriptions={[tooltipDescriptions[2]]}
            activeTooltip={activeTooltip}
            setActiveTooltip={setActiveTooltip}
          />
          <Tooltip
            Title="U"
            Subtitle="Usability"
            descriptions={[tooltipDescriptions[3]]}
            activeTooltip={activeTooltip}
            setActiveTooltip={setActiveTooltip}
          />
          <Tooltip
            Title="S"
            Subtitle="Scalability"
            descriptions={[tooltipDescriptions[4]]}
            activeTooltip={activeTooltip}
            setActiveTooltip={setActiveTooltip}
          />
        </div>
      </div>
    </>
  );
}

export default StandaloneSection;
