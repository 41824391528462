import React from "react";
import "./PortfolioFooter.css";

function PortfolioFooter() {
  return (
    <div className="mt-6 dark:text-white text-center mb-8 flex justify-center items-center ">
      <a href="#">
        <h2 className="text-xl sm:text-4xl md:text-3xl font-serif font-bold ">
          See all Projects
        </h2>
      </a>
      <a
        href="#"
        className="hover:translate-x-2 transition-transform duration-300"
      >
        <svg
          className="w-6 h-6 text-gray-800 dark:text-white ml-2"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 8 14"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 13 5.7-5.326a.909.909 0 0 0 0-1.348L1 1"
          />
        </svg>
      </a>
    </div>
  );
}

export default PortfolioFooter;
