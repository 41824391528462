import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./Testimonial.css"; // Import your custom CSS file for additional styling

function TestimonialsCarousel({ testimonials }) {
  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false, // Turn off autoplay
  };

  const goToNextSlide = () => {
    sliderRef.current.slickNext();
  };

  const goToPrevSlide = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <section className="px-4 pb-10 pt-5 ">
      <div className="testimonial-container mt-7">
        <Slider ref={sliderRef} {...settings}>
          {testimonials.map((testimonial, index) => (
            <div className="testimonial-item" key={index}>
              <div className="flex justify-center items-center">
                <img
                  src={testimonial.img}
                  className="w-24 rounded-full shadow-lg dark:shadow-black/30"
                  alt={`Testimonial ${index + 1}`}
                />
              </div>
              <p className="my-4 text-xl text-neutral-500 dark:text-neutral-300">
                {testimonial.description}
              </p>
              <p className="italic">{testimonial.name}</p>
            </div>
          ))}
        </Slider>
        <div className="slider-controls  flex justify-end items-center mt-6">
          <button
            onClick={goToPrevSlide}
            className="control-button bg-transparent text-4xl text-gray-600 hover:bg-gray-900 hover:text-white rounded-full w-12 h-12 pb-1 flex items-center justify-center"
          >
            &larr;
          </button>
          <button
            onClick={goToNextSlide}
            className="control-button bg-transparent text-4xl text-gray-600 hover:bg-gray-900 hover:text-white rounded-full w-12 h-12  pb-1 flex items-center justify-center"
          >
            &rarr;
          </button>
        </div>
      </div>
    </section>
  );
}

export default TestimonialsCarousel;
