import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import technoscaffold_logo from "../../assests/images/logo/Technoscaffold final logo-01.png";
import './Navbar.css';


const navigation = [
  { name: "Home", href: "/" },
  { name: "Services", href: "/services" },
  { name: "Expertise", href: "/expertise" },
  { name: "Portfolio", href: "/portfolio" },
  { name: "AboutUs", href: "/aboutUs" },
  { name: "Career", href: "/career" },
];

export default function Navbar() {
  const location = useLocation();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

const closeMobileMenu = () => {
  setMobileMenuOpen(false);
};

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="bg-white">
      <header className="absolute inset-x-0 top-0 z-50 ">
        <nav
          className="flex items-center shadow-lg	 justify-between fixed top-0 w-full navBackground p-4  lg:px-8"
          aria-label="Global"
        >
          <div className="flex lg:flex-1 items-center">
            <Link to="/" className="-m-1.5 p-1.5 lg:h-2.4">
              <span className="sr-only">Your Company</span>
              <img
                className="h-12 lg:h-12 w-auto sm:h-8 sm:w-auto"
                src={technoscaffold_logo}
                alt=""
              />
            </Link>
          </div>

          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <Link
                key={item.name}
                to={item.href}
                className={`text-sm font-semibold leading-6 text-gray-600 ${
                  location.pathname === item.href ? "active-link" : ""
                } hover:text-teal-600`}
              >
                {item.name}
              </Link>
            ))}
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <Link
              to="/contactUs"
              className="text-sm font-semibold uppercase leading-6 text-gray-900 onhover"
            >
              Contact Us
              {/* <span aria-hidden="true">&rarr;</span> */}
            </Link>
          </div>
        </nav>
        <Dialog
          as="div"
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className=" fixed inset-y-0 right-0 shadow-lg z-50 w-full overflow-y-auto navBackground px-4 py-4 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between ">
              <Link to="/" className="-m-1.5 p-1.5 lg:h-2.4">
                <span className="sr-only">Your Company</span>
                <img
                  className="h-12 lg:h-12 w-auto sm:h-8 sm:w-auto"
                  src={technoscaffold_logo}
                  alt=""
                />
              </Link>

              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <Link
                      key={item.name}
                      to={item.href}
                      className={`"-mx-3 block rounded-lg px-3 py-2 text-md font-bold leading-7 text-gray-700${
                        location.pathname === item.href ? " active-link" : ""
                      }`}
                      onClick={closeMobileMenu}
                    >
                      {item.name}
                    </Link>
                  ))}
                </div>
                <div className="py-1">
                  <Link
                    to="/contactUs"
                    className={`"-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"${
                      location.pathname === "/contactUs" ? " active-link" : ""
                    }`}
                    onClick={closeMobileMenu}
                  >
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>
    </div>
  );
}
