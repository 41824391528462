import React from "react";
import image from "../../assests/images/robot.png";
import Breadcrumbs from "../../layout/Breadcrumbs/Breadcrumbs";
import Testimonial from "../../pages/Home/Testimonial/Testimonial";
import "./AboutUs.css";
import CardGrid from "./CardGrid";
import CardService from "./CardService";

function AboutUs() {
  return (
    <>
      <div className="flex flex-wrap mt-24 mb-2 ">
        <div className="lg:w-full md:w-full sm:w-full flex flex-wrap mb-5">
          <div className="lg:w-6/12 md:w-6/12 sm:w-full p-4 sm:p-6 md:p-8">
            <div className="float-left ml-6">
              <Breadcrumbs
                firsthand="Home"
                Secondhand="AboutUs"
                Redirected="/AboutUs"
              />
            </div>
            <div className="intro-left mt-6 sm:mt-8 md:mt-8 lg:mt-0">
              <div className="intro-name">
                <h3 className="text-2xl font-semibold leading-tight">
                  Empowering Your Business with Technological Excellence
                </h3>
                <h5 className="text-xl text-gray-900 mt-4">
                  <span className="text-teal-600 font-bold">
                    Welcome to Technoscaffold,{" "}
                  </span>
                  where innovation meets technology. We are a dynamic software
                  development and design firm specializing in empowering
                  companies across diverse sectors such as PropTech, FinTech,
                  and HealthTech.
                </h5>
              </div>
            </div>
          </div>

          <div className="lg:w-6/12 md:w-6/12 sm:w-full flex justify-center items-center">
            <img className="About-img" src={image} alt="logo" />
          </div>
        </div>

        <div className="mt-5">
          <div className=" text-center ">
            <h3 className="mb-6 text-3xl font-bold">
              <span className="custom-double-underline">About Us</span>
            </h3>
          </div>
          <div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-6 p-4 sm:p-6 md:p-8 lg:p-12 justify-center mb-3">
            <CardGrid
              text="Our Expertise"
              Description="With a team of skilled professionals, we bring a wealth of technical expertise to the table. Our in-depth knowledge and experience empower us to craft solutions that are not only advanced but also practical and effective."
            />
            <CardGrid
              text="Our Dedication"
              Description="Our dedication to your success is unwavering. We go above and beyond to ensure your satisfaction. We are not just a service provider; we are your committed partner, invested in the growth and prosperity of your business."
            />
            <CardGrid
              text="Our Adaptability"
              Description="In a rapidly evolving technological landscape, adaptability is key. We embrace change and are quick to adapt to new technologies and methodologies. This agility allows us to stay ahead of the curve and deliver forward-thinking solutions."
            />
          </div>
          <div>
            <div className=" text-center mt-8 ">
              <h3 className="mb-6 text-3xl font-bold">
                <span className="custom-double-underline">
                  Services We Deliver
                </span>
              </h3>
            </div>
            <div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-14 p-4 sm:p-6 md:p-8 lg:p-12 justify-center">
              <CardService
                Title="UX/UI Design"
                Description="Elevate your digital presence with our vital UX/UI design services. We transform complex interactions into seamless flows, enhancing user experiences and boosting conversions."
              />
              <CardService
                Title="Iot Development"
                Description="Experience secure, robust, and scalable solutions across diverse industries. From concept to launch and ongoing support, Technoscaffold delivers comprehensive product development services to meet your business needs."
              />
              <CardService
                Title="Product Development"
                Description="Explore the future with our end-to-end IoT services, covering design, development, and the launch of connected solutions. Technoscaffold believes in the vast potential of IoT, offering innovative solutions for businesses."
              />
              <CardService
                Title="Digital Transformation"
                Description="Seize strategic opportunities in the modern world with our digital transformation services. We redefine how you deliver value, solve problems, and support your team, ensuring a shift that aligns with the evolving landscape."
              />
            </div>
          </div>
        </div>
      </div>
      <Testimonial />
    </>
  );
}

export default AboutUs;
