import React from "react";
import "./Career.css";
function CareerCard({ Title, Description }) {
  return (
    <>
      <div className="flex flex-col bg-teal-300 rounded-2xl">
        <div className="px-6 py-8 sm:p-10 sm:pb-6">
          <div className="grid items-center justify-center w-full grid-cols-1 text-left">
            <div>
              <h2 className="text-lg font-medium tracking-tighter text-gray-700 lg:text-3xl">
                {Title}
              </h2>
              <p className="mt-2 text-sm text-gray-500">{Description} </p>
            </div>
            {/* <div className="mt-6">
              <p>
                <span className="text-5xl font-light tracking-tight text-black">
                  $25
                </span>
                <span className="text-base font-medium text-gray-500"> /mo </span>
              </p>
            </div> */}
          </div>
        </div>
        <div className="flex px-6 pb-8 sm:px-8">
          <a
            aria-describedby="tier-company"
            className="flex items-center uppercase justify-center px-4 py-1.5 text-center text-white duration-200 bg-gray-800 border-2 border-gray-800 rounded-3xl inline-flex hover:bg-transparent hover:border-black hover:text-black focus:outline-none focus-visible:outline-black text-sm focus-visible:ring-black"
            href="#"
          >
            Apply Now{" "}
          </a>
        </div>
      </div>
    </>
  );
}

export default CareerCard;
