// import { useGetAboutMeQuery, useGetHomeDetailsQuery } from "../../Api/api";
// import decor1 from "./../../../src/assests/images//dots-1.png";

import { Link } from "react-router-dom";
import image from "./../../../assests/images/lapi.png";
import "./About.css";
const AboutMe = () => {
  return (
    <>
      <div>
        <div className="background-color">
          <h1 className="white-text-with-blue-shadow center-text pt-8 pb-8 ">
            TURN ORDINARY INTO EXTRAORDINARY!
          </h1>
          <p className="center-paragraph">
            At TechnoScoffold, we're more than a team; we're a collective of
            visionary technologists, creative designers, and strategic thinkers,
            all united by a common purpose: to elevate your digital presence and
            unlock your online potential. With a wealth of expertise and an
            unwavering commitment to staying at the forefront of technology
            trends, we proudly serve as your trusted partner in the
            ever-evolving digital landscape.
          </p>
        </div>
        <section className="Aboutcontainer">
          <img src={image} alt="Your Image" className="image" />
          <div className="content">
            <h1 className="sub-title text-2xl font-extrabold mb-3">
              TechnoScaffold
            </h1>
            <h3 className="text-xl font-bold	text-neutral-700 font-sans uppercase">
              Software Development Excellence{" "}
            </h3>
            <p className="paragraph mt-3 mb-6">
              As a software development company, we're passionate about crafting
              tailored software solutions using the latest technologies. From
              dynamic applications to innovative web development, our team
              specializes in harnessing the power of cutting-edge tools to
              create solutions that perfectly fit your needs and vision."
            </p>
            <div className="checkitout-btn ">
              <Link to="/services" className="Checkme ">
                <button className="check-me">Check It Out</button>
              </Link>
            </div>
          </div>
        </section>
        <section className="service-container">
          <img src={image} alt="My Image" className="service-image" />
          <div className="service-content">
            <h3 className="text-xl font-bold text-neutral-700 font-sans subtitle1">
              WE'RE EQUIPPED FOR EXCELLENCE!{" "}
            </h3>
            {/* <h1 className="sub-title">ABOUT US</h1> */}

            <p className="paragraph mt-3">
              Cultivating Software Brilliance with a Foundation in Innovation!
              Our tech-savvy experts consistently deliver cutting-edge
              excellence. From crafting intelligent software solutions to
              dynamic applications, our team is renowned for driving digital
              growth and transforming businesses worldwide{" "}
            </p>
            <br />
            <div className="checkitout-btn">
              <a href="/" className="Checkme">
                <button className="check-me">Check It Out</button>
              </a>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default AboutMe;
