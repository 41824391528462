import { default as React, useEffect, useState } from "react";
import {
  default as image1,
  default as image2,
} from "../../../assests/images/Finance2.png";
import ExpertiseCard from "../../../components/Card/ExpertiseCard/ExpertiseCard";
import PortfolioFooter from "./PortfolioFooter/PortfolioFooter";
import PortfolioTitle from "./PortfolioTitle/PortfolioTitle";

function Portfolio() {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkIsMobile();

    window.addEventListener("resize", checkIsMobile);

    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);

  return (
    <>
      <PortfolioTitle />
      <ExpertiseCard
        title="Planet Fitness (Fitness)"
        categories={[
          "Fitness",
          "Innovative Solutions",
          "Interactive Workout Apps",
          "Connected Fitness Equipments",
        ]}
        description="At TechnoScaffold, we've had the privilege of working alongside Planet Fitness to reshape the fitness industry. Our innovative solutions have redefined the way people engage with fitness, making it more accessible, engaging, and rewarding. From interactive workout apps to connected fitness equipment, we've empowered Planet Fitness to inspire and transform lives, one step at a time."
        image={image1}
        imagePosition="right"
        is_mobile={isMobile ? "mobile" : "web"}
      />

      <ExpertiseCard
        title="Mortgage Web Application"
        categories={["Finance", "Mortgage", "Lending Process", "Efficiency"]}
        description=" Our collaboration with leading financial institutions has ushered in a new era in the world of finance. Through our cutting-edge mortgage web application, we've revolutionized the lending process, making it seamless and efficient. We've enabled borrowers to navigate the complex world of mortgages with ease, ensuring a stress-free and empowering experience."
        image={image2}
        imagePosition="left"
        is_mobile={isMobile ? "mobile" : "web"}
      />
      <PortfolioFooter />
      {/* <ExpertiseCard
        title="E-commerce App (E-commerce)"
        categories={[
          "E-commerce",
          "Online Retail",
          "User-Centric",
          "Secure Transactions",
        ]}
        description=" TechnoScaffold has partnered with top e-commerce brands to elevate the online retail landscape. Our user-centric e-commerce app has redefined the way consumers shop, offering an immersive and personalized experience. From intuitive navigation to secure transactions, we've empowered businesses to thrive in the competitive world of online retail."
        image={image2} // Replace with the image URL
        imagePosition="right" // Specify image position as 'right'
      /> */}
      {/* <ExpertiseCard
        title="Finmonk (Finance)"
        categories={[
          "Finance",
          "FinTech",
          "Financial Growth",
          "Automated Investment Platforms",
        ]}
        description="Finmonk represents our commitment to empowering financial growth and prosperity through innovative FinTech solutions. By leveraging our expertise, we've equipped individuals and businesses with the tools they need to make informed financial decisions. From automated investment platforms to robust financial analytics, we've brought the power of finance to the fingertips of our clients."
        image={image2} // Replace with the image URL
        imagePosition="right" // Specify image position as 'right'
      />
      <ExpertiseCard
        title="NextGen (E-Learning)"
        categories={[
          "E-Learning",
          "Education",
          "Immersive Online Courses",
          "Data-Driven Insights",
        ]}
        description="TechnoScaffold is at the forefront of shaping the future of education with our cutting-edge e-learning solutions. Our collaboration with NextGen institutions has transformed traditional education models. Through immersive online courses, interactive learning platforms, and data-driven insights, we're equipping learners with the skills they need to thrive in the digital age."
        image={image2} // Replace with the image URL
        imagePosition="left" // Specify image position as 'right'
      /> */}
    </>
  );
}

export default Portfolio;
