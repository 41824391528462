import React, { useState } from "react";
import "./Tooltip.css";

function Tooltip({
  Title,
  Subtitle,
  descriptions,
  activeTooltip,
  setActiveTooltip,
}) {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
    setActiveTooltip(Title);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div
      className="relative group"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <span className="font-bold underline decoration-4 underline-offset-9 decoration-slate-300 focusTitle transition-transform duration-200 transform group-hover:scale-110 custom-small-title sm:custom-medium-title md:custom-large-title lg:custom-extra-large-title xl:custom-extra-large-title">
        {Title}
      </span>
      {showTooltip ? (
        <div className="tooltip-container">
          <div className="tooltip-content">
            <h1 className="text-xl font-bold">{Subtitle}</h1>
            <div className="tooltip-slider">
              {descriptions.map((description, index) => (
                <div key={index}>
                  <p className="tooltip-description">{description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Tooltip;
