import {
  default as image3,
  default as image5,
} from "../../assests/images/Ecommerce.png";
import {
  default as image1,
  default as image4,
} from "../../assests/images/Finance2.png";
import { default as image2 } from "../../assests/images/Mortgage.png";

const initialState = {
  isMobile: false,
  expertiseCards: [
    {
      id: "card1",
      title: "Fitness",
      description:
        "In partnership with Planet Fitness, we've redefined fitness, making it accessible and rewarding. Our innovative apps and connected equipment inspire transformative journeys, one step at a time. Together, we're reshaping lives and the fitness industry.",
      image: image1,
      imagePosition: "right",
    },
    {
      id: "card2",
      title: "Finance",
      description:
        " In partnership with top financial institutions, our innovative mortgage web application has transformed lending, simplifying the process and empowering borrowers with a seamless, stress-free experience. Welcome to a new era of effortless finance.",
      image: image2,
      imagePosition: "left",
    },
    {
      id: "card3",
      title: "E-commerce",
      description:
        " TechnoScaffold's partnership with major e-commerce brands has redefined online retail, offering a personalized, secure, and immersive shopping experience that empowers businesses to thrive in the digital landscape.",
      image: image3, // Replace with the image URL
      imagePosition: "right", // Specify image position as 'right' // Specify image position as 'right'
    },
    {
      id: "card4",
      title: "Finance",
      description:
        "Finmonk revolutionizes finance with innovative FinTech solutions, empowering individuals and businesses through automated investments and robust financial analytics for informed decision-making.",
      image: image4, // Replace with the image URL
      imagePosition: "left", // Specify image position as 'right'
    },
    {
      id: "card5",

      title: "E-Learning",
      description:
        "TechnoScaffold pioneers the future of education, revolutionizing learning through immersive online courses, interactive platforms, and data-driven insights, preparing learners for success in the digital age.",
      image: image5, // Replace with the image URL
      imagePosition: "right", // Specify image position as 'right'
    },
    // Add more cards as needed
  ],
};

const ExpertiseItems = (state = initialState, action) => {
  // Handle your actions here
  return state;
};

export default ExpertiseItems;
