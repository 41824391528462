import { default as React } from "react";
import { connect } from "react-redux";
import ExpertiseCard from "../../components/Card/ExpertiseCard/ExpertiseCard";

import Breadcrumbs from "../../layout/Breadcrumbs/Breadcrumbs";
import "./Portfolio.css";
// import PortfolioTitle from "./PortfolioTitle/PortfolioTitle";
function Portfolio({ isMobile, PortfolioCards }) {
  return (
    <>
      <div className="background-Expertise-container mt-24 bg-center bg-cover sm:bg-center sm:bg-cover md:bg-top">
        <div className="flex flex-wrap justify-center items-center mb-8 p-4 md:p-9">
          <div className="w-full md:w-5/8 lg:w-7/8 md:mb-36 lg:mb-36 sm:mb-12">
            <div className="float-left ml-6">
              <Breadcrumbs
                firsthand="Home"
                Secondhand="Portfolio"
                Redirected="/Portfolio"
              />
            </div>
            <div className="intro-left mt-4 md:mt-0">
              <div className="intro-name">
                <h3 className=" uppercase font-semibold md:mt-14 animate-fade-in">
                  Elevating Boundaries:
                </h3>
                <br />
                <h2 className=" mt-4 font-semibold space-4 animate-fade-in">
                  Explore TechnoScaffold's Digital Innovation{" "}
                  <span className="text-blue-800 mr-2"> Portfolio </span>- Where
                  Boundaries Cease and Innovation Begins
                </h2>
              </div>
            </div>
          </div>
        </div>

      <div class="mx-auto max-w-3xl text-center mt-10">
        <h3 class="mb-6 text-3xl font-bold">
          <span class="custom-double-underline">Our Portfolios</span>
        </h3>
        {/* </div> */}
      </div>
      {PortfolioCards.map((card) => (
        <ExpertiseCard
        key={card.id}
        title={card.title}
        categories={card.categories}
        description={card.description}
          image={card.image}
          imagePosition={card.imagePosition}
          is_mobile={isMobile ? "mobile" : "web"}
        />
      ))}
</div>
    </>
  );
}

const mapStateToProps = (state) => ({
  isMobile: state.portfolio.isMobile,
  PortfolioCards: state.portfolio.PortfolioCards,
});

export default connect(mapStateToProps)(Portfolio);
