import React from "react";
import "./PortfolioTitle.css";
function PortfolioTitle() {
  return (
    <div className="PortfolioTitle mt-10 sm:mt-5 md:mt-7 lg:mt-10">
      <h2 className="text-2xl sm:text-4xl md:text-3xl font-bold dark:text-white text-gray-800 text-center mb-4 MainPortfolioTitle">
        Building Solutions that Work
      </h2>
      <br />
      <h2 className="text-base sm:text-lg md:text-base font-sans text-center max-w-[760px] mx-auto">
        We make things happen. Always. Our high-powered tech experts set up the
        entire software development processes, detect problems, and solve them.{" "}
      </h2>
    </div>
  );
}

export default PortfolioTitle;
