import "aos/dist/aos.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom"; // Import the 'Routes' component
import Home from "../src/pages/Home/Home";
import "./App.css";
import Footer from "./layout/Footer/Footer";
import Navbar from "./layout/Navbar/Navbar";
import AboutUs from "./pages/AboutUs/AboutUs";
import Career from "./pages/Career/Career";
import ContactUs from "./pages/ContactUs/ContactUs";
import Expertise from "./pages/Expertise/Expertise";
import Portfolio from "./pages/Portfolio/Portfolio";
import Services from "./pages/Services/Services";

function App() {
  return (
    <>
      {/* <NavbarSimple /> */}
      <Router>

        <Navbar />
        
        <Routes>  
          {" "}
          {/* Wrap your Routes */}
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/expertise" element={<Expertise />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/aboutUs" element={<AboutUs />} />
          <Route path="/career" element={<Career />} />
          <Route path="/contactUs" element={<ContactUs />} />
          {/* Add more routes as needed */}
        </Routes>
      <Footer />
      </Router>
      {/* <Hero /> */}
    </>
  );
}

export default App;
