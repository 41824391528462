import React from "react";
import { Link } from "react-router-dom";


function CardService({ Title, Description, imagePosition }) {

  return (
    <>
      <div className="bg-white text-black  max-w-md mx-auto rounded-xl shadow-md overflow-hidden md:max-w-2xl  hover:shadow-2xl hover:bg-gray-100 transition-all duration-500 ease-in-out">
        <div className="md:flex">
          <div className="p-8">
            <div className="uppercase tracking-wide text-2xl text-gray-800 font-semibold">
              {Title}
            </div>
            <p className="mt-2 text-gray-600">{Description}</p><br />
            {/* <Link to="/Aboutus" className="text-teal-400 text-md underline">Learn More</Link> */}
          </div>
        </div>
        
      </div>
    </>
  );
}

export default CardService;
