import { default as React, useState } from "react";
import { default as image1 } from "../../assests/images/Fitness App.jpg";
import { default as image2 } from "../../assests/images/Mortgage.png";
import image from "../../assests/images/robot.png";
import ExpertiseCard from "../../components/Card/ExpertiseCard/ExpertiseCard";
import Breadcrumbs from "../../layout/Breadcrumbs/Breadcrumbs";
import Testimonials from "../Home/Testimonial/Testimonial";
import "./Career.css";
import CareerCard from "./CareerCard";

function Careers() {
  const [isMobile, setIsMobile] = useState(false);

  return (
    <>
      <div className="flex flex-wrap mt-24 mb-2 ">
        <div className="lg:w-6/12 md:w-6/12 sm:w-full p-9 ">
          <div className="float-left ml-6">
            <Breadcrumbs
              firsthand="Home"
              Secondhand="Career"
              Redirected="/career"
            />
          </div>
          <div className="intro-left">
            <div className="intro-name mt-14 ">
              <h3
                className="text-2xl font-semibold leading-tight" /* Add animation classes here */
              >
                Inspire Your Career Journey with TechnoScaffold
                {/* Services We Offer */}
              </h3>
              <h5
                className=" text-xl text-gray-900 mt-4" /* Add animation classes here */
              >
                "Join{" "}
                <span className="text-teal-600 font-bold">
                  TechnoScaffold's{" "}
                </span>
                innovative team, where creativity has no limits, ideas flourish,
                and potential soars. Dive into impactful projects and craft a
                future where every career is a masterpiece, inspiring the world
                together."
              </h5>
            </div>
            {/* <div className="intro-btns">
              <a href="/" className="contactMe">
                <button className="bg-teal-500 hover:bg-teal-700 text-white font-bold py-2 px-4 rounded">
                  Let's talk
                </button>
              </a>
            </div> */}
            <br />
          </div>
        </div>
        <div className="lg:w-6/12 lg:mt-10 md:w-6/12 md:mt-10 sm:w-full p-4">
          <div className="ff">
            <img className="About-img" src={image} alt="logo" />
          </div>
        </div>
        <div className="mx-auto max-w-3xl text-center">
          <h3 className="mb-6 text-3xl font-bold">
            <span className="custom-double-underline">Open Positions</span>
          </h3>
        </div>
        <div className="grid sm:grid-cols-2 mb-8 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-6 p-4 sm:p-6 md:p-8 justify-center">
          <CareerCard
            Title="ReactJs Developer"
            Description="Join TechnoScaffold in Junagadh as a skilled React JS Developer. Collaborate on high-quality React.js components, ensuring seamless user experiences. Strong JavaScript, HTML, CSS, and Redux skills required."
            Location="Junagadh,Gujarat"
          />
          <CareerCard
            Title="UI/UX DESIGNER"
            Description="TechnoScaffold in Junagadh is hiring a creative UI/UX Designer. Craft captivating interfaces, enhance user experiences, and drive innovation in just a few lines. Join us and shape the future of design!"
            Location="Junagadh,Gujarat"
          />
          <CareerCard
            Title="Python Developer"
            Description="Experienced Python Developer wanted at TechnoScaffold in Junagadh. Leverage Python's power for efficient and scalable solutions. Join our innovative projects and shape the digital landscape."
          />
        </div>
        <div className="mx-auto max-w-3xl text-center">
          <h3 className="mb-6 text-3xl font-bold">
            <span className="custom-double-underline">
              Build your career with TechnoScaffold
            </span>
          </h3>
        </div>
        <div>
          <ExpertiseCard
            title="Our Approach"
            description="In partnership with Planet Fitness, we've redefined fitness, making it accessible and rewarding. Our innovative apps and connected equipment inspire transformative journeys, one step at a time. Together, we're reshaping lives and the fitness industry."
            image={image1}
            imagePosition="right"
            is_mobile={isMobile ? "mobile" : "web"}
          />
          <ExpertiseCard
            title="Our Impactful Solutions"
            description="We are passionate about creating solutions that leave a lasting impact. From innovative apps to robust platforms, our creations are designed to transform challenges into opportunities, empowering your business to thrive in the digital age."
            image={image1}
            imagePosition="left"
            is_mobile={isMobile ? "mobile" : "web"}
          />
          <ExpertiseCard
            className="ab"
            title="Our Customer Focus"
            description="Your satisfacti is our priority. We listen to your needs, respond promptly to your queries, and provide personalized attention. Our customer-centric approach ensures a seamless experience, making us more than just a service provider – we are your trusted ally"
            image={image2}
            imagePosition="right"
            is_mobile={isMobile ? "mobile" : "web"}
          />
          <ExpertiseCard
            title="Why Choose Us ?"
            description="Opt for Technoscaffold's transformative solutions, marrying innovation and user-centric design for intuitive interfaces. Cultivate success through strategic partnerships, collaboratively achieving your business goals."
            image={image2}
            imagePosition="left"
            is_mobile={isMobile ? "mobile" : "web"}
          />
        </div>

        {/* </div> */}
      </div>
      <Testimonials />
    </>
  );
}

export default Careers;
