import React from "react";
import "./Services.css"; // Import the CSS file

export default function ServiceCard({ title, description, image }) {
  return (
    <div className="card">
      <div className="card-content">
        <img src={image} alt="sdkf" />
        <h2>{title}</h2>
        <p>{description}</p>
        <a href="#" className="know-more">
          Know More
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M15 11v4.833A1.166 1.166 0 0 1 13.833 17H2.167A1.167 1.167 0 0 1 1 15.833V4.167A1.166 1.166 0 0 1 2.167 3h4.618m4.447-2H17v5.768M9.111 8.889l7.778-7.778"
            />
          </svg>
        </a>
      </div>
    </div>
  );
}
