import "animate.css";
import image from "./../../../assests/images/01.png";
import './Hero.css';
const Intro = () => {
  /*
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchHomeApiData() // Call the API function
      .then((response) => {
        setData(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
 */
  return (
    <>
      {/* <div className="Background-Color"> */}
      <div className="flex flex-wrap pt-32 pr-0 pb-8 pl-2 bg-56cfe1">
        <div className="lg:w-5/12 md:w-6/12 sm:w-full p-9">
          <div className="intro-left">
            <div className="intro-name">
              <h1 className="text-xl font-bold	text-neutral-700 font-sans	">
                TechnoScaffold
                <span className="text-neutral-800 font-sans">
                  <span>Connecting People</span>
                  <span>Everyday Excellence</span>
                  <span>Building Dreams</span>
                  <span>Quality You Can Trust</span>
                  <span>Driven by Innovation</span>
                </span>
              </h1>
              {/* <h3
                className="text-xl font-semibold hello " 
              >
                Beyond Boundaries
              </h3> */}
              <h3
                className="text-xl font-semibold name" /* Add animation classes here */
              >
                Constructing Futures
              </h3>
              <p className="text-gray-700" /* Add animation classes here */>
                We help businesses win new markets with innovative solutions
                that disrupt industries
              </p>
            </div>
            <div className="intro-btns" /* Add animation classes here */>
              <a href="/" className="contactMe">
                <button className="hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                  Let's talk
                </button>
              </a>
            </div>
            <br />
          </div>
        </div>
        <div className="lg:w-7/12 lg:mt-16 md:w-6/12 md:mt-14 sm:w-full p-4">
          <div className="ff">
            <img className="intro-img" src={image} alt="logo" />
          </div>
        </div>
      </div>
      {/* <section className="relative bg-[url(https://images.unsplash.com/photo-1604014237800-1c9102c219da?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80)] bg-cover bg-center bg-no-repeat">
        <div className="absolute inset-0 bg-gradient-to-r from-white/95 via-white/50 to-white/25 sm:from-white/95 sm:via-white/30 sm:to-white/85 ltr:sm:bg-gradient-to-r rtl:sm:bg-gradient-to-l"></div>
        <div className="relative mx-auto max-w-screen-xl px-4 py-32 sm:px-6 lg:flex lg:h-screen lg:items-center lg:px-8">
          <div className="max-w-xl text-center ltr:sm:text-left rtl:sm:text-right">
            <h1 className="text-3xl font-extrabold sm:text-5xl">
              Let us find your
              <strong className="block font-extrabold text-rose-700">
                Forever Home.
              </strong>
            </h1>

            <p className="mt-4 max-w-lg sm:text-xl/relaxed">
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt
              illo tenetur fuga ducimus numquam ea!
            </p>

            <div className="mt-8 flex flex-wrap gap-4 text-center">
              <a
                href="#"
                className="block w-full rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white shadow hover:bg-rose-700 focus:outline-none focus:ring active:bg-rose-500 sm:w-auto"
              >
                Get Started
              </a>

              <a
                href="#"
                className="block w-full rounded bg-white px-12 py-3 text-sm font-medium text-rose-600 shadow hover:text-rose-700 focus:outline-none focus:ring active:text-rose-500 sm:w-auto"
              >
                Learn More
              </a>
            </div>
          </div>
        </div>
      </section> */}
      {/* </div> */}
    </>
  );
};

export default Intro;
