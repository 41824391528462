import React from "react";
import TestimonialItem from "./TestimonialItems";
const testimonialData = [
  {
    name: "- Teresa May",
    img: "https://tecdn.b-cdn.net/img/Photos/Avatars/img%20(22).jpg",
    description:
      "Neque cupiditate assumenda in maiores repudiandae mollitia architecto elit sed adipiscing elit, ",
  },
  {
    name: "- Teresa May",
    img: "https://tecdn.b-cdn.net/img/Photos/Avatars/img%20(2).jpg",
    description:
      "Neque cupiditate assumenda in maiores repudiandae mollitia architecto elit sed adipiscing elit.",
  },
  {
    name: "- Teresa May",
    img: "https://tecdn.b-cdn.net/img/Photos/Avatars/img%20(12).jpg",
    description:
      "Neque cupiditate assumenda in maiores repudiandae mollitia architecto elit sed adipiscing elit.",
  },
  {
    name: "- Anna Morian",
    img: "https://tecdn.b-cdn.net/img/Photos/Avatars/img%20(10).jpg",
    description:
      "Lorem ipsum dolor sit amet eos adipisci, consectetur adipisicing elit sed ut perspiciatis unde omnis.",
  },
  // Add more testimonials as needed
];

function App() {
  return (
    <div className="App bg-green-100 dark:bg-green-300 pt-8">
      <div className="mx-auto max-w-3xl text-center">
        <h3 className="mb-6 text-3xl font-bold">Testimonials</h3>
        <p className="mb-6 pb-2 text-neutral-500 dark:text-neutral-300 md:mb-12">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit, error
          amet numquam iure provident voluptate esse quasi, veritatis totam
          voluptas nostrum quisquam eum porro a pariatur veniam.
        </p>
      </div>
      <TestimonialItem testimonials={testimonialData} />
    </div>
  );
}

export default App;
