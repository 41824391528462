import React from "react";
import image1 from "../../.../../assests/images/Consultancy.svg";
import image2 from "../../.../../assests/images/FullStack.svg";
import image3 from "../../.../../assests/images/service 1.svg";
import image4 from "../../../src/assests/images/BlockChian.png";
import image from "../../../src/assests/images/services.gif";
import Breadcrumbs from "../../layout/Breadcrumbs/Breadcrumbs";
import Testimonials from "../Home/Testimonial/Testimonial";
import ServiceCard from "./ServiceCard";
import "./Services.css";
function Services() {
  return (
    <>
      <div className="flex flex-wrap mt-24 mb-8">
        <div className="lg:w-5/12 md:w-6/12 sm:w-full p-9">
          <div className="float-left ml-6">
            <Breadcrumbs
              firsthand="Home"
              Secondhand="Services"
              Redirected="/Services"
            />
          </div>
          <div className="intro-left">
            <div className="intro-name mt-14">
              <h1 className="text-2xl mb-4 mt-4 font-semibold">~ Services </h1>
              <h3
                className="text-2xl font-semibold" /* Add animation classes here */
              >
                Services We Offer
              </h3>

              <p
                className="text-gray-700 mt-4" /* Add animation classes here */
              >
                Digital transformation & Product Engineering services for
                customers around the globe
              </p>
            </div>
            {/* <div className="intro-btns">
              <a href="/" className="contactMe">
                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                  Let's talk
                </button>
              </a>
            </div> */}
            <br />
          </div>
        </div>
        <div className="lg:w-7/12 lg:mt-1 md:w-6/12 md:mt-14 sm:w-full p-4">
          <div className="ff">
            <img className="intro-img" src={image} alt="logo" />
          </div>
        </div>
      </div>
      <div class="mx-auto max-w-3xl text-center">
        <h3 class="mb-6 text-3xl font-bold">
          <span class="custom-double-underline">Our Services</span>
        </h3>
        {/* </div> */}
      </div>

      <div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-6 p-4 sm:p-6 md:p-8 lg:p-12 justify-center">
        <ServiceCard
          title="Web Application Development"
          description="Creating web-based applications and platforms that are user-friendly, scalable, and efficient."
          image={image3}
        />
        <ServiceCard
          title="Full-Stack Development"
          description="Developing robust server-side logic, databases, and APIs to ensure seamless functionality."
          image={image2}
        />
        {/* <ServiceCard
          title="Consulting and Advisory"
          description="Offering expertise and guidance on technology selection, project planning, and digital strategy."
          image={image1}
        /> */}
        <ServiceCard
          title="Frontend Development"
          description="Crafting intuitive and responsive user interfaces (UI) for web and mobile applications."
          image={image2}
        />
        <ServiceCard
          title="Backend Development"
          description=" Offering end-to-end development services, including backend development expertise."
          image={image1}
        />

        <ServiceCard
          title="Blockchain Development"
          description="Creating decentralized apps & implementing blockchain solutions for diverse industries."
          image={image4}
        />
        {/* <ServiceCard
          title="IoT (Internet of Things) Development"
          description="Creating applications that connect and interact with IoT devices for smart solutions."
          image={image5}
        /> */}
      </div>

      <Testimonials />
    </>
  );
}

export default Services;
